<template>
  <LeftSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Upgraded to the New Standards</h2>
      <p class="desc">
        It is a major responsibility to keep an eye on the achievements of the
        data center and making sure it is upgraded to the new standards of the
        business world. Modification in automated technology covering all the
        remedial records, directing weathercasts, movies, videos and much more
        are programmed with modern digital technology. IT administrators are
        compelled to contemplate data management procedures in order to compose
        massive amounts of unorganized data.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/data-center/unnamed.png"
        alt="Upgraded to the New Standards"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fivth-data-section",
};
</script>
