<template>
  <data-center />
</template>

<script>
import DataCenter from "../../components/solutions/data-center/DataCenter.vue";
export default {
  components: { DataCenter },
  name: "data-center-page",
};
</script>
