<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Data centers in Iraq</h2>
      <p class="desc">
        Today’s competitive world mandates a much more advanced approach than
        the traditional data center. A modular-based data center will be the
        most promising alternative to augment your delivery duties from your
        core data centers. Your service delivery model will be well adapted
        toward amassing the extraordinarily perceptive data, cost containment,
        and a superb level of accommodation.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/data-center/data.png"
        alt="Data centers in Iraq"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-data-section",
};
</script>
