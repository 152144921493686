<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="title-blue h1">Design and Implement Solutions</h2>
    <p class="my-5">
      However, consenting for a new data center or colocation center can damage
      every sector of the firm. It calls for supervision in the migration of
      hardware, database, client software, networks along with network
      scheduling, execution, and testing for both internal networks as well as
      external WANs, Internet, and telephone.
    </p>
  </div>
</template>

<script>
export default {
  name: "third-data-section",
};
</script>
