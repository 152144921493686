<template>
  <RightSection>
    <div class="info-desc col-lg-8 mt-5">
      <h2 class="title-green h1">Supervision in the Migration of Hardware</h2>
      <p class="desc">
        Practical and profitable migration urges intelligent forethought with
        perfectly consistent implementation. Supervised with reasonable
        effortless and amenable migration that will benefit your vaults data
        center team.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/data-center/3-design-and-implement-solutions-2.png"
        alt="Supervision in the Migration of Hardware"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-data-section",
};
</script>
