<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        Build Solitary Optimized Computing Batches
      </h2>
      <p class="desc">
        From midsize industries to big investment clients across various arenas
        like Windows, Linux, Unix & VMware, you can benefit from our server
        solutions which are correlated to distinct applications workload. We
        create different hardware and software defined systems to build solitary
        optimized computing batches from numerous elements. The SharePoint and
        Lync provide integrated archiving. Also, we also help the System
        Applications and Products in data management the business operations.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/data-center/3-design-and-implement-solutions-1.png"
        alt="Build Solitary Optimized Computing Batches"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-data-section",
};
</script>

<style></style>
