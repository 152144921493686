<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">How to Create a Data Center?</h2>
      <p class="desc">
        After taking into account your company’s needs and setting an
        infrastructure that is suitable for it, we orient toward furnishing the
        best IT framework. Not only will we evaluate your business procedure
        supervision model but also tailor to govern you in specifying the best
        software and hardware preferences while supporting you in enforcing the
        essential IT infrastructure. Moreover, we will capitalize on a step to
        step difficult free transition from your previous infrastructure toward
        the new one.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/data-center/data-center.png"
        alt="How to Create a Data Center?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  nmae: "first-data-section",
};
</script>
